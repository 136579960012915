.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
.tab-contant .ant-upload-drag {
  min-height: 70px;
}
.tab-contant .ant-upload-drag .ant-upload {
  padding: 0;
}
.tab-contant .ant-upload-drag .ant-upload .ant-upload-text {
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  color: "#000000";
}
.tab-contant .preview-image-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 10px;
}
.tab-contant .preview-image-container .preview-file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
}
.upload-btn-wrapper .btn {
  border: 1px solid #3556f1;
  background-color: #fafafb;
  padding: 9px 16px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  color: #3556f1;
}
.upload-btn-wrapper .btn img {
  padding-right: 8px;
}
.upload-btn-wrapper input[type="file"] {
  font-size: 0;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.upload-btn-wrapper .file-upload-part {
  border: 1px dashed #d8dee4;
  min-height: 50px;
  width: 100%;
  justify-content: center;
  display: inline-flex;
  align-items: center;
}
.upload-btn-wrapper .file-upload-part .ant-upload-drag-icon .anticon {
  font-size: 30px;
  color: #3556f1;
}
.upload-btn-wrapper .file-upload-part .ant-upload-text {
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  color: #1a3353;
}
.upload-btn-wrapper.highlight .file-upload-part {
  border: 1px solid #3556f1 !important;
}
.card-delete-btn {
  min-width: 20px;
  height: 20px;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
}
.card-delete-btn img {
  max-width: 100%;
}
.ant-collapse-extra span {
  line-height: 40px;
  font-weight: 500;
}
.collapse-extra-container {
  width: 500px;
}
.collapse-extra-container .delete-btn {
  color: red;
}
.result-html {
  padding: 2px 10px;
  overflow: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.ant-card-bordered {
  height: 200px;
}
.login-card {
  height: 100%;
}
.card-body-contant .note-description {
  height: 120px;
  width: 100%;
}
.card-body-contant .note-description img {
  max-width: 100%;
  height: 100%;
  object-fit: contain;
}
.text-editor-preview {
  max-height: 439px;
  overflow: auto;
  height: 100%;
}
.collapse-panel-container {
  position: relative;
}
.collapse-panel-container .collapse-edit-btn {
  position: absolute;
  right: 130px;
  top: 3px;
  border: none;
}
.collapse-panel-container .collapse-edit-btn .anticon {
  margin-right: 8px;
}
.collapse-panel-container .collapse-edit-btn:hover {
  background-color: transparent;
}
.collapse-panel-container .collapse-delete-btn {
  position: absolute;
  right: 35px;
  top: 3px;
  border: none;
}
.collapse-panel-container .collapse-delete-btn .anticon {
  margin-right: 8px;
}
.collapse-panel-container .collapse-delete-btn:hover {
  background-color: transparent;
}
.ant-layout-content .report-list-container {
  border: 1px solid #e6ebf1;
  border-radius: 0.625rem;
}
.ant-layout-content .report-list-container .report-list-item {
  padding: 12px 16px;
  color: #1a3353;
  line-height: 1.5;
  border-bottom: 1px solid #e6ebf1;
}
